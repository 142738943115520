/**
 * ⚠️ these will be exposed on the client ⚠️
 */

const { settings } = require('@buzzfeed/rig');
const NODE_ENV = process.env.NODE_ENV || 'production';

/**
 * Use CDN in stage & prod
 *   In unlikely case of not wanting to use CDN (and serve assets directly from service)
 *     this can be set to '' for all environments
 */
const ASSET_PREFIX =
  NODE_ENV === 'production'
    ? '/static-assets/feed-ui'
    : '';

const envConfig = { ASSET_PREFIX, NODE_ENV };

try {
  const CLUSTER = settings.get('cluster');
  const RIG_DEPLOYMENT_TYPE = settings.get('rig_deployment_type');
  const client = settings.get('client');

  if (NODE_ENV === 'production' && CLUSTER === 'dev') {
    envConfig.ASSET_PREFIX = ``;
  }

  envConfig.CLUSTER = CLUSTER;
  envConfig.RIG_DEPLOYMENT_TYPE = RIG_DEPLOYMENT_TYPE;
  Object.assign(envConfig, client);
} catch (e) {}

module.exports = envConfig;
